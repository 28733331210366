import { App } from '@/app.models';
import { MODULES } from '@/constants';
import { Store } from 'vuex';

export const setAuthUrls = (params: {
    authConfig: App.AuthConfig;
    store: Store<unknown>;
}): void => {
    const loginUrlParams = new URLSearchParams();
    loginUrlParams.append('grant_type', 'authorization_code');
    loginUrlParams.append('response_type', 'code');
    loginUrlParams.append('redirect_uri', params.authConfig.redirectUri);
    loginUrlParams.append('client_id', params.authConfig.clientId);
    loginUrlParams.append('scope', params.authConfig.scope);

    params.store.commit(
        `${MODULES.AUTH.STORE.NAMESPACE.AUTH}/${MODULES.AUTH.STORE.MUTATION.SET_LOGIN_URL}`,
        [
            params.authConfig.baseUrl,
            params.authConfig.loginEndpoint,
            '?',
            loginUrlParams.toString(),
        ].join('')
    );

    const logoutUrlParams = new URLSearchParams();
    logoutUrlParams.append('client_id', params.authConfig.clientId);
    logoutUrlParams.append('logout_uri', params.authConfig.logoutUri);

    params.store.commit(
        `${MODULES.AUTH.STORE.NAMESPACE.AUTH}/${MODULES.AUTH.STORE.MUTATION.SET_LOGOUT_URL}`,
        [
            params.authConfig.baseUrl,
            params.authConfig.logoutEndpoint,
            '?',
            logoutUrlParams.toString(),
        ].join('')
    );
};
