import { MODULES } from '@/constants';
import AuthService from '@/modules/auth/services/auth-service';
import { Store } from 'vuex';

// TODO cache keys in the state instead...
// let cacheKeys: Auth.MapOfKidToPublicKey | undefined;

class TokenVerificationError extends Error {}

const getKeys = async (params: {
    authService: AuthService;
    jwkToPem: (jwt: any) => string;
    header: Auth.TokenHeader;
}): Promise<Auth.PublicKeyMeta> => {
    // if (cacheKeys) {
    //     return cacheKeys[header.kid];
    // }

    const response = await params.authService.getPublicKeys();
    const result = ((response.keys as unknown) as Auth.PublicKey[]).reduce(
        (agg: Auth.MapOfKidToPublicKey, current: Auth.PublicKey) => {
            const pem = params.jwkToPem(current);
            agg[current.kid] = { instance: current, pem };
            return agg;
        },
        {} as Auth.MapOfKidToPublicKey
    );

    return (result as Auth.MapOfKidToPublicKey)[params.header.kid];
};

export const verifyAuthToken = (params: {
    token: string;
    verify: Auth.TVerifyJWT;
    jwkToPem: Auth.TJWKToPEM;
    authService: AuthService;
    cognitoIssuer: string;
    store: Store<unknown>;
}): void => {
    (async () => {
        if (!params.token) return;

        try {
            const splitToken = params.token.split('.');
            if (splitToken.length < 2) {
                throw new TokenVerificationError(
                    MODULES.AUTH.TOKEN_VERIFICATION_STATUS.INVALID_TOKEN
                );
            }

            const key = await getKeys({
                authService: params.authService,
                jwkToPem: params.jwkToPem,
                header: JSON.parse(
                    Buffer.from(splitToken[0], 'base64').toString('utf-8')
                ),
            });

            if (key === undefined) {
                throw new TokenVerificationError(
                    MODULES.AUTH.TOKEN_VERIFICATION_STATUS.UNKNOWN_KID
                );
            }

            const claim = params.verify(params.token, key.pem) as Auth.Claim; // If this fails, throws a TokenExpiredError
            const currentSeconds = Math.floor(new Date().valueOf() / 1000);
            if (
                currentSeconds > claim.exp ||
                currentSeconds < claim.auth_time
            ) {
                throw new TokenVerificationError(
                    MODULES.AUTH.TOKEN_VERIFICATION_STATUS.CLAIM_EXPIRED_OR_INVALID
                );
            }
            if (claim.iss !== params.cognitoIssuer) {
                throw new TokenVerificationError(
                    MODULES.AUTH.TOKEN_VERIFICATION_STATUS.CLAIM_ISSUER_IS_INVALID
                );
            }

            if (claim.token_use !== 'id') {
                throw new TokenVerificationError(
                    MODULES.AUTH.TOKEN_VERIFICATION_STATUS.CLAIM_USE_IS_NOT_FOR_ID
                );
            }

            params.store.commit(
                `${MODULES.AUTH.STORE.NAMESPACE.AUTH}/${MODULES.AUTH.STORE.MUTATION.SET_TOKEN_VERIFICATION_STATUS}`,
                MODULES.AUTH.TOKEN_VERIFICATION_STATUS.TOKEN_VERIFIED
            );

            params.store.commit(
                `${MODULES.AUTH.STORE.NAMESPACE.AUTH}/${MODULES.AUTH.STORE.MUTATION.SET_USER_CLAIM_ID}`,
                claim
            );
        } catch (e) {
            if (e.name === 'TokenExpiredError') {
                params.store.commit(
                    `${MODULES.AUTH.STORE.NAMESPACE.AUTH}/${MODULES.AUTH.STORE.MUTATION.SET_TOKEN_VERIFICATION_STATUS}`,
                    MODULES.AUTH.TOKEN_VERIFICATION_STATUS.TOKEN_EXPIRED
                );
            } else if (e instanceof TokenVerificationError) {
                params.store.commit(
                    `${MODULES.AUTH.STORE.NAMESPACE.AUTH}/${MODULES.AUTH.STORE.MUTATION.SET_TOKEN_VERIFICATION_STATUS}`,
                    e.message
                );
            } else {
                throw e;
            }
        }
    })();
};
