









import config from '@/config';
import { MODULES } from '@/constants';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { setAuthUrls } from '../effects/set-auth-urls';
import { verifyAuthToken } from '../effects/verify-auth-token';
import { verify } from 'jsonwebtoken';
import jwkToPem from 'jwk-to-pem';
import AuthService from '../services/auth-service';
import { refreshAuthToken } from '../effects/refresh-auth-token';

@Component
export default class AuthWrapper extends Vue {
    get idToken(): string {
        return this.$store.getters[
            `${MODULES.AUTH.STORE.NAMESPACE.AUTH}/${MODULES.AUTH.STORE.GETTER.USER_TOKENS}`
        ].id_token;
    }

    get refreshToken(): string {
        return this.$store.getters[
            `${MODULES.AUTH.STORE.NAMESPACE.AUTH}/${MODULES.AUTH.STORE.GETTER.USER_TOKENS}`
        ].refresh_token;
    }

    get tokenVerificationStatus(): string {
        return this.$store.getters[
            `${MODULES.AUTH.STORE.NAMESPACE.AUTH}/${MODULES.AUTH.STORE.GETTER.TOKEN_VERIFICATION_STATUS}`
        ];
    }

    get isTokenVerified(): boolean {
        return (
            this.tokenVerificationStatus ===
            MODULES.AUTH.TOKEN_VERIFICATION_STATUS.TOKEN_VERIFIED
        );
    }

    get loginUrl(): string {
        return this.$store.getters[
            `${MODULES.AUTH.STORE.NAMESPACE.AUTH}/${MODULES.AUTH.STORE.GETTER.LOGIN_URL}`
        ];
    }

    get logoutUrl(): string {
        return this.$store.getters[
            `${MODULES.AUTH.STORE.NAMESPACE.AUTH}/${MODULES.AUTH.STORE.GETTER.LOGOUT_URL}`
        ];
    }

    authService = new AuthService(config.auth);

    mounted(): void {
        setAuthUrls({
            authConfig: config.auth,
            store: this.$store,
        });
    }

    @Watch('idToken', { immediate: true })
    onIdTokenChanged(newIdToken: string, oldIdToken: string): void {
        if (newIdToken === oldIdToken) return;

        if (!newIdToken) return;

        verifyAuthToken({
            token: newIdToken,
            authService: this.authService,
            cognitoIssuer: config.auth.cognitoIssuer,
            store: this.$store,
            verify,
            jwkToPem,
        });
    }

    @Watch('tokenVerificationStatus', { immediate: true })
    onTokenVerificationChanged(newStatus: string, oldStatus: string): void {
        if (newStatus === oldStatus) return;

        if (!newStatus) return;

        refreshAuthToken({
            tokenVerificationStatus: newStatus,
            authService: this.authService,
            refreshToken: this.refreshToken,
            store: this.$store,
        });
    }
}

/*
    useEffect(() => syncUserTokensToStateEffect(props.storage, d), []);


    useEffect(
        () =>
            refreshAuthTokenEffect(
                r.tokenVerificationStatus,
                authService(props.config.auth),
                d,
                r.userTokens.refresh_token
            ),
        [r.tokenVerificationStatus]
    );
    useEffect(() => {
        registerAuthTokenErrorInterceptorEffect(
            d,
            authService(props.config.auth),
            r.userTokens.refresh_token
        );
    }, []);

    const isAuthCallback = window.location.pathname === '/auth_callback';

    const isTokenCached = props.storage.getItem(
        MODULES.CORE.STORAGE.STORAGE_KEY_USER_TOKENS
    );

    const isTokenVerified =
        r.tokenVerificationStatus ===
        MODULES.CORE.TOKEN_VERIFICATION_STATUS.TOKEN_VERIFIED;

*/
