import { MODULES } from '@/constants';
import { Store } from 'vuex';
import AuthService from '@/modules/auth/services/auth-service';

export const refreshAuthToken = (params: {
    tokenVerificationStatus: string;
    authService: AuthService;
    refreshToken: string | undefined;
    store: Store<unknown>;
}): void => {
    if (!params.refreshToken) return;

    if (
        params.tokenVerificationStatus ===
        MODULES.AUTH.TOKEN_VERIFICATION_STATUS.TOKEN_EXPIRED
    )
        params.authService
            .exchangeRefreshTokenForAuthToken(params.refreshToken)
            .then((r: Auth.UserTokens) => {
                params.store.commit(
                    `${MODULES.AUTH.STORE.NAMESPACE.AUTH}/${MODULES.AUTH.STORE.MUTATION.SET_USER_TOKENS}`,
                    r
                );
            })
            .catch((e) => {
                console.error(
                    'Error while exchanging refresh token for auth token',
                    e
                );
            });
};
